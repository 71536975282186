<template>
  <div>
    <!-- Add New Sidebar -->
    <channel-list-sidebar
      :is-channel-list-sidebar-active.sync="isChannelListSidebarActive"
      :method-options="methodOptions"
      :provider-options="providerOptions"
      :region-options="regionOptions"
      :data="channelInfo"
      @updated="queryChannelList"
    />

    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Method</label>
            <v-select
              v-model="methodFilter"
              label="type_desc"
              :options="methodOptions"
              :clearable="false"
              class="w-100"
              :reduce="val => val.type_id"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Provider</label>
            <v-select
              v-model="providerFilter"
              label="provider_name"
              :options="providerOptions"
              :clearable="false"
              class="w-100"
              :reduce="val => val.provider_id"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Region</label>
            <v-select
              v-model="regionFilter"
              label="region_name"
              :options="regionOptions"
              :clearable="false"
              class="w-100"
              :reduce="val => val.region_id"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>Default</label>
            <v-select
              v-model="isDefaultFilter"
              :options="isDefaultOptions"
              :clearable="false"
              :searchable="false"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              @click="addNewChannel"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Add New</span>
            </b-button>
            <b-button
              v-if="$can('write', 'Financials')"
              class="ml-2"
              variant="outline-warning"
              @click="exportChannelFees"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Export Fees</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="channelOptinos"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="cid"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- Column: Name -->
        <template #cell(cname)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block"
              :to="{ name: 'channel-fees', params: { cid: data.item.cid } }"
            >
              {{ data.item.cname }}
            </b-link>
            <small class="text-muted">@{{ data.item.cid }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(is_default)="data">
          <b-badge
            pill
            :variant="`light-${resolveDefaultVariant(data.item.is_default)}`"
            class="ml-1"
          >
            <feather-icon
              :icon="`${resolveDefaultIcon(data.item.is_default)}`"
            />
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap ml-2">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="showDetail(data.item)"
            />
            <feather-icon
              v-b-tooltip.hover.left="showChannelName(data.item.backup)"
              :icon="data.item.monitoring ? 'ToggleLeftIcon' : 'ToggleRightIcon'"
              :color="data.item.monitoring ? 'red' : 'black'"
              class="cursor-pointer ml-1"
              size="16"
              @click="showMonitorConfig(data.item)"
            />
            <feather-icon
              icon="Link2Icon"
              class="cursor-pointer ml-1"
              size="16"
              @click="showBackupConfig(data.item)"
            />
            <feather-icon
              icon="BellIcon"
              :color="data.item.alarm ? 'red' : 'black'"
              class="cursor-pointer ml-1"
              size="16"
            />
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalChannels"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- monitor modal -->
    <b-modal
      :visible="isConfirmModalActive"
      title="Monitoring Configs"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      centered
      :no-close-on-backdrop="true"
      @show="resetModal"
      @hidden="resetModal"
      @ok="toggleMonitoring"
      @change="(val) => isConfirmModalActive = val"
    >
      <div class="mb-2">
        <label for="interval">Interval (Minites)</label>
        <b-form-spinbutton
          id="interval"
          v-model="interval"
          min="1"
          max="720"
          wrap
        />
      </div>
      <b-row>
        <b-col md="6">
          <div>
            <label for="successThreshold">Threshold Of Success Rate (%)</label>
            <b-form-spinbutton
              id="successThreshold"
              v-model="successThreshold"
              min="0"
              max="100"
              wrap
            />
          </div>
        </b-col>
        <b-col md="6">
          <div>
            <label for="refuseThreshold">Threshold Of Refuse Rate (%)</label>
            <b-form-spinbutton
              id="refuseThreshold"
              v-model="refuseThreshold"
              min="0"
              max="100"
              wrap
            />
          </div>
        </b-col>
      </b-row>
      <b-form-checkbox
        v-model="needAlarm"
        class="mt-2"
        name="check-button"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="BellIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="BellOffIcon" />
        </span>
      </b-form-checkbox>
    </b-modal>

    <!-- backup modal -->
    <b-modal
      :visible="isBackupModalActive"
      title="Select A Backup Channel"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      centered
      :no-close-on-backdrop="true"
      @show="resetModal"
      @hidden="resetModal"
      @ok="doBackupChannel"
      @change="(val) => isBackupModalActive = val"
    >
      <label class="mt-1">Backup</label>
      <v-select
        v-model="backupChannel"
        label="cname"
        :options="backupChannelOptions"
        :clearable="true"
        class="w-100"
        :reduce="val => val.cid"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BCardHeader, BCardBody, BMedia, BLink,
  BButton, BFormInput, BBadge, BModal, VBModal, BFormSpinbutton, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast, showAlert } from '@/libs/tool'
import useChannelsJs from './channels'
import ChannelListSidebar from './ChannelListSidebar.vue'

const {
  fetchChannelFilters,
  fetchChannels,
  toggleMonitoring,
  doBackupChannel,
  exportChannelFees,
} = useChannelsJs()

export default {
  components: {
    ChannelListSidebar,

    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BPagination,
    BMedia,
    BLink,
    BButton,
    BFormInput,
    BBadge,
    BModal,
    BFormSpinbutton,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      methodFilter: '',
      methodOptions: [
        { type_id: '', type_name: '', type_desc: 'All' },
      ],

      providerFilter: '',
      providerOptions: [
        { provider_id: '', provider_type: '', provider_name: 'All' },
      ],

      regionFilter: '',
      regionOptions: [
        { region_id: '', region_code: '', region_name: 'All' },
      ],

      isDefaultFilter: '',
      isDefaultOptions: [
        { label: 'All', value: '' },
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],

      channels: [],
      filter: null,
      filterOn: ['cname', 'cid'],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      tableFields: [
        { key: 'cname', label: 'NAME' },
        { key: 'type_name', label: 'METHOD' },
        { key: 'provider_name', label: 'PROVIDER' },
        { key: 'region_name', label: 'REGION' },
        { key: 'extra', label: 'INDEX' },
        { key: 'is_default', label: 'DEFAULT' },
        { key: 'status', label: 'STATUS' },
        'actions',
      ],
      currentPage: 1,
      totalChannels: 0,
      isConfirmModalActive: false,
      isBackupModalActive: false,
      selectedChannelId: '',
      interval: 0,
      needAlarm: true,
      successThreshold: 0,
      refuseThreshold: 100,

      isChannelListSidebarActive: false,
      channelInfo: {},
      backupChannel: '',
      backupChannelOptions: [],
    }
  },
  computed: {
    channelOptinos() {
      const cl = []
      this.channels.forEach(item => {
        let jump = false
        if (this.methodFilter !== '' && this.methodFilter !== item.type) {
          jump = true
        }
        if (this.providerFilter !== '' && this.providerFilter !== item.provider) {
          jump = true
        }
        if (this.regionFilter !== '' && this.regionFilter !== item.region) {
          jump = true
        }
        if (this.isDefaultFilter !== '' && this.isDefaultFilter !== item.is_default) {
          jump = true
        }
        if (jump) {
          return
        }
        cl.push(item)
      })
      return cl
    },
  },
  watch: {
    currentPage() {
      this.recalMetaData()
    },
    channelOptinos() {
      this.totalChannels = this.channelOptinos.length
      this.recalMetaData()
    },
    needAlarm(val) {
      if (!val) {
        this.$swal({
          title: 'Are you sure?',
          text: 'Turning OFF the alert will prevent you from receiving PHONE & SMS notifications!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'I Know, Turn it OFF!',
          cancelButtonText: 'Stay ON',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (!result.isConfirmed) {
            this.needAlarm = true
          }
        })
      }
    },
  },
  created() {
    fetchChannelFilters(
      filters => {
        this.methodOptions = filters.methods
        this.providerOptions = filters.providers
        this.regionOptions = filters.regions
      },
      fail => {
        showAlert(this, 'warning', 'Fail!', `${fail}`)
      },
    )
    this.queryChannelList()
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalChannels = filteredItems.length
      this.currentPage = 1
      this.recalMetaData()
    },
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalChannels - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalChannels
      this.dataMeta.of = this.totalChannels
    },
    resolveDefaultVariant(val) {
      if (val) return 'success'
      if (!val) return 'secondary'
      return 'warning'
    },
    resolveDefaultIcon(val) {
      if (val) return 'CheckIcon'
      if (!val) return 'XIcon'
      return 'XIcon'
    },
    resolveStatusVariant(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === 0) return 'Disabled'
      if (status === 1) return 'Enabled'
      return 'Unknown'
    },
    queryChannelList() {
      fetchChannels(
        channels => {
          this.channels = channels
          this.totalChannels = channels.length
          this.recalMetaData()
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    showDetail(detail) {
      this.channelInfo = detail
      this.isChannelListSidebarActive = true
    },
    resetModal() {
      this.backupChannel = ''
    },
    addNewChannel() {
      this.channelInfo = {
        is_new: true,
      }
      this.isChannelListSidebarActive = true
    },
    showMonitorConfig(channel) {
      this.selectedChannelId = channel.cid
      this.needAlarm = channel.alarm
      if (channel.thresholds) {
        const [i, s, r] = channel.thresholds.split(':')
        this.interval = parseInt(i, 0)
        this.successThreshold = parseInt(s, 0)
        this.refuseThreshold = parseInt(r, 0)
      } else {
        this.interval = 0
        this.successThreshold = 100
        this.refuseThreshold = 0
      }

      if (channel.monitoring) {
        this.toggleMonitoring()
      } else {
        this.needAlarm = true
        this.isConfirmModalActive = true
      }
    },
    toggleMonitoring() {
      const params = {
        cid: this.selectedChannelId,
        interval: this.interval,
        need_alarm: this.needAlarm,
        success_threshold: this.successThreshold,
        refuse_threshold: this.refuseThreshold,
      }
      toggleMonitoring(
        params,
        success => {
          showAlert(this, 'success', 'Success!', `${success}`)
          this.queryChannelList()
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    showBackupConfig(channel) {
      this.selectedChannelId = channel.cid

      const cl = []
      this.channels.forEach(item => {
        let jump = false
        if (channel.type !== item.type) {
          jump = true
        }
        if (channel.region !== item.region) {
          jump = true
        }
        if (jump) {
          return
        }
        cl.push(item)
      })
      this.backupChannelOptions = cl

      setTimeout(() => {
        this.backupChannel = channel.backup
      }, 200)

      this.isBackupModalActive = true
    },
    doBackupChannel() {
      const params = { cid: this.selectedChannelId, backup: this.backupChannel ? this.backupChannel : '' }
      doBackupChannel(
        params,
        success => {
          showAlert(this, 'success', 'Success!', `${success}`)
          this.queryChannelList()
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    showChannelName(cid) {
      const channel = this.channels.find(r => r.cid === cid)
      return channel ? channel.cname : ''
    },
    exportChannelFees() {
      exportChannelFees(
        response => {
          const { headers } = response
          const contentType = headers['content-type']

          if (response.data) {
            const blob = new Blob([response.data], {
              type: contentType,
            })

            const contentDisposition = headers['content-disposition']
            let fileName = 'unknown'
            if (contentDisposition) {
              fileName = window.decodeURI(headers['content-disposition'].split('=')[1])
            }
            this.downFile(blob, fileName)
          }
        },
        fail => {
          showToast(this, 'Warning', `export fail with ${fail}.`)
        },
      )
    },
    downFile(blob, fileName) {
      if ('download' in document.createElement('a')) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      } else {
        window.navigator.msSaveBlob(blob, fileName)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
