<template>
  <b-sidebar
    id="channel-list-sidebar"
    :visible="isChannelListSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-channel-list-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Channel Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <!-- Type -->
          <b-form-group
            label="Method"
            label-for="method"
          >
            <v-select
              v-model="method"
              :options="methodOptions"
              label="type_desc"
              :clearable="false"
              :reduce="val => val.type_id"
              input-id="method"
              :disabled="!isNew"
            />
          </b-form-group>

          <!-- Provider -->
          <b-form-group
            label="Provider"
            label-for="provider"
          >
            <v-select
              v-model="provider"
              :options="providerOptions"
              label="provider_name"
              :clearable="false"
              :reduce="val => val.provider_id"
              input-id="provider"
              :disabled="!isNew"
            />
          </b-form-group>

          <!-- Region -->
          <b-form-group
            label="Region"
            label-for="region"
          >
            <v-select
              v-model="region"
              :options="regionOptions"
              label="region_name"
              :clearable="false"
              :reduce="val => val.region_id"
              input-id="region"
              :disabled="!isNew"
            />
          </b-form-group>

          <!-- Extra (Index) -->
          <validation-provider
            #default="{ errors }"
            name="Extra"
            rules="required"
          >
            <b-form-group
              label="Extra"
              label-for="extra"
            >
              <b-form-input
                id="extra"
                v-model="extra"
                type="number"
                autofocus
                trim
                :disabled="!isNew"
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Icon URL -->
          <b-form-group
            label="Icon URL"
            label-for="icon-url"
          >
            <b-form-input
              id="icon-url"
              v-model="iconUrl"
              autofocus
              trim
            />
          </b-form-group>

          <!-- is Default -->
          <b-form-group
            label="Default"
            label-for="default"
          >
            <v-select
              v-model="isDefault"
              :options="isDefaultOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="default"
            />
          </b-form-group>

          <!-- Name -->
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="description"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Lower Limit -->
          <validation-provider
            #default="{ errors }"
            name="Lower Limit"
            rules="required"
          >
            <b-form-group
              label="Lower Limit"
              label-for="lower-limit"
            >
              <b-form-input
                id="lower-limit"
                v-model="lowerLimit"
                type="number"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Upper Limit -->
          <validation-provider
            #default="{ errors }"
            name="Upper Limit"
            rules="required"
          >
            <b-form-group
              label="Upper Limit"
              label-for="upper-limit"
            >
              <b-form-input
                id="upper-limit"
                v-model="upperLimit"
                type="number"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Currency Precision -->
          <b-form-group
            label="Currency Precision"
            label-for="precision"
          >
            <v-select
              v-model="precision"
              :options="precisionOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="precision"
            />
          </b-form-group>

          <!-- Rounding Mode -->
          <b-form-group
            label="Rounding Mode"
            label-for="rounding_mode"
          >
            <v-select
              v-model="roundingMode"
              :options="roundingModeOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="rounding_mode"
            />
          </b-form-group>

          <el-divider />

          <!-- Refund API -->
          <b-form-group
            label="Refund API"
            label-for="refund-api"
          >
            <v-select
              v-model="refundable"
              :options="refundApiOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="refund-api"
            />
          </b-form-group>

          <!-- Retrieve API -->
          <b-form-group
            label="Retrieve API"
            label-for="retrieve-api"
          >
            <v-select
              v-model="retrievable"
              :options="retrieveApiOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="retrieve-api"
            />
          </b-form-group>

          <!-- Cancel API -->
          <b-form-group
            label="Cancel API"
            label-for="cancel-api"
          >
            <v-select
              v-model="cancellable"
              :options="cancelApiOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="cancel-api"
            />
          </b-form-group>

          <!-- implementer -->
          <b-form-group
            label="Implementer"
            label-for="implementer"
          >
            <v-select
              v-model="implementer"
              :options="implementerOptions"
              :clearable="false"
              :searchable="false"
              input-id="implementer"
            />
          </b-form-group>

          <el-divider />

          <!-- Status -->
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="status"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="status"
            />
          </b-form-group>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useChannelsJs from './channels'

const {
  saveChannel,
} = useChannelsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isChannelListSidebarActive',
    event: 'update:is-channel-list-sidebar-active',
  },
  props: {
    isChannelListSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    methodOptions: {
      type: Array,
      required: true,
    },
    providerOptions: {
      type: Array,
      required: true,
    },
    regionOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isNew: false,
      cid: '',
      method: '',
      provider: '',
      region: '',
      extra: '',
      iconUrl: '',
      isDefault: false,
      name: '',
      description: '',
      lowerLimit: -1,
      upperLimit: -1,
      precision: '2',
      roundingMode: '2',
      refundable: 0,
      retrievable: 0,
      cancellable: 0,
      implementer: '',
      status: 0,
      error: '',

      precisionOptions: [
        { label: '2 Decimal Places', value: '2' },
        { label: 'Integer', value: '0' },
      ],
      roundingModeOptions: [
        { label: 'Ceiling', value: '2' },
        { label: 'Round', value: '4' },
        { label: 'Floor', value: '3' },
      ],

      isDefaultOptions: [
        { label: 'All', value: '' },
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],

      statusOptions: [
        { label: 'Disable', value: 0 },
        { label: 'Enable', value: 1 },
      ],

      refundApiOptions: [
        { label: 'Not Supported', value: 0 },
        { label: 'Partial Supported', value: 1 },
        { label: 'Only Full Supported', value: 2 },
      ],

      retrieveApiOptions: [
        { label: 'Not Supported', value: 0 },
        { label: 'Supported', value: 1 },
      ],

      cancelApiOptions: [
        { label: 'Not Supported', value: 0 },
        { label: 'Supported', value: 1 },
      ],

      implementerOptions: [
        'PHP',
        'GO',
      ],

      required,
    }
  },
  computed: {
    capacity: {
      get() {
        let lowerLimit = ''
        let upperLimit = ''
        if (this.lowerLimit === -1) {
          lowerLimit = '~'
        } else {
          lowerLimit = this.lowerLimit
        }
        if (this.upperLimit === -1) {
          upperLimit = '~'
        } else {
          upperLimit = this.upperLimit
        }
        return `${lowerLimit},${upperLimit}`
      },
      set(val) {
        const [lowerLimit, upperLimit] = val.split(',')
        if (lowerLimit === '~') {
          this.lowerLimit = -1
        }
        if (upperLimit === '~') {
          this.upperLimit = -1
        }
      },
    },
  },
  watch: {
    isChannelListSidebarActive(val) {
      this.isNew = this.data.is_new
      if (!val || this.data.is_new) {
        this.initValues()
        return
      }
      this.initChannelInfo(this.data)
    },
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    initChannelInfo(info) {
      this.cid = info.cid
      this.method = info.type
      this.provider = info.provider
      this.region = info.region
      this.extra = info.extra
      this.iconUrl = info.icon_url
      this.isDefault = info.is_default
      this.name = info.cname
      this.description = info.description
      const capacity = info.capacity.split(',')
      const [lowerLimit, upperLimit] = capacity
      this.lowerLimit = lowerLimit === '~' ? -1 : lowerLimit
      this.upperLimit = upperLimit === '~' ? -1 : upperLimit
      const currencyPrecision = info.currency_precision ? info.currency_precision.split(',') : ['2', '2']
      const [precision, roundingMode] = currencyPrecision
      this.precision = precision
      this.roundingMode = roundingMode
      this.status = info.status
      this.refundable = info.refundable ? info.refundable : 0
      this.retrievable = info.retrievable ? info.retrievable : 0
      this.cancellable = info.cancellable ? info.cancellable : 0
      this.implementer = info.implementer
    },
    initValues() {
      this.cid = ''
      this.method = ''
      this.provider = ''
      this.region = ''
      this.extra = ''
      this.iconUrl = ''
      this.isDefault = false
      this.name = ''
      this.description = ''
      this.lowerLimit = -1
      this.upperLimit = -1
      this.precision = 2
      this.roundingMode = 2
      this.status = 0
      this.refundable = 0
      this.retrievable = 0
      this.cancellable = 0
      this.implementer = ''
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.update()
            }
          })
        }
      })
    },
    update() {
      const methodName = this.methodOptions.filter(m => m.type_id === this.method)[0].type_name
      const providerName = this.providerOptions.filter(m => m.provider_id === this.provider)[0].provider_code
      const regionName = this.regionOptions.filter(m => m.region_id === this.region)[0].region_code
      const lower = this.lowerLimit === '-1' ? '~' : this.lowerLimit
      const upper = this.upperLimit === '-1' ? '~' : this.upperLimit
      const capacity = `${lower},${upper}`
      const currencyPrecision = `${this.precision},${this.roundingMode}`
      const obj = {
        cid: this.cid,
        cname: this.name,
        type: this.method,
        type_name: methodName,
        provider: this.provider,
        provider_name: providerName,
        region: this.region,
        region_name: regionName,
        extra: this.extra,
        icon_url: this.iconUrl,
        is_default: this.isDefault,
        capacity,
        currency_precision: currencyPrecision,
        description: this.description,
        status: this.status,
        refundable: this.refundable,
        retrievable: this.retrievable,
        cancellable: this.cancellable,
        implementer: this.implementer,
      }
      saveChannel(
        obj,
        () => {
          this.showAlert('success', 'Updated!', 'Success')
          this.$emit('update:is-channel-list-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
